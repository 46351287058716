<template>
  <div class="form">
    <div style="position: relative">
      <!-- <img src="@/assets/top-img.png" class="top-img" /> -->
      <img :src="titImg" class="top-img" />

      <div class="bind flex align" @click="bind">
        <van-icon size="18" name="wechat" /><span>{{ chartText }}</span>
      </div>
      <div class="out flex align" @click="out()">退出登陆</div>
      <div class="record flex align" @click="toRecord()">打卡记录</div>
    </div>

    <div class="site flex align">
      <img src="@/assets/bg5.png" class="bg5" />
      <img src="@/assets/icon5.png" class="icon" />当前点位：{{ site }}
    </div>
    <div class="main">
      <div class="tit">基本信息</div>
      <div class="form-div">
        <!-- 输入任意文本 -->
        <van-field
          v-model="info.name"
          label="姓名"
          placeholder="请输入您的名字"
        />
        <van-field
          v-model="info.id_number"
          label="身份证"
          placeholder="请输入您的身份号码"
        />
        <van-field
          v-model="sex_t"
          label="性别"
          placeholder="请选择您的性别"
          @click="toShow('show1')"
          is-link
          readonly
        />
        <!-- <van-field
          @click="showDate = true"
          v-model="info.birthday"
          label="出生日期"
          placeholder="请选择您的出生日期"
          is-link
          readonly
        /> -->
        <!-- <van-field
          v-model="political_status_t"
          label="政治面貌"
          placeholder="请选择您的政治面貌"
          @click="toShow('show4')"
          is-link
          readonly
        /> -->
        <!-- <van-field
          v-model="info.household_address"
          label="户口所在地"
          placeholder="请输入您的户籍所在地"
        /> -->
        <van-field
          v-model="info.family_address"
          label="家庭住址"
          placeholder="请输入您的家庭住址"
        />
        <van-field
          v-model="info.telephone"
          label="电话"
          type="tel"
          placeholder="请输入您的电话号码"
        />
      </div>
      <div class="tit">残疾人信息</div>
      <van-field
        v-if="has_children != 1"
        v-model="info.disability_cert_no"
        label="残疾证号"
        placeholder="请输入您的残疾证号"
      />
      <van-field
        v-model="disability_type_t"
        label="残疾类别"
        placeholder="请选择您的残疾类别"
        @click="toShow('show2')"
        is-link
        readonly
      />
      <van-field
        v-model="disability_level_t"
        label="残疾等级"
        placeholder="请选择您的残疾等级"
        @click="toShow('show3')"
        is-link
        readonly
      />
      <div class="flex up">
        <van-field
          style="width: 31%"
          label="人员照片"
          placeholder=""
          readonly
          :border="false"
        />
        <div class="uploader">
          <img src="@/assets/upload.png" class="up-img" v-if="headImg == ''" />
          <img :src="headImg" class="up-img" v-else />
          <input
            class="uploader-div"
            ref="uploadVideo"
            type="file"
            id="logimg"
            accept="image/*"
            @change="onRead($event)"
          />
        </div>
      </div>
      <div class="flex up" v-if="has_children == 1">
        <van-field
          style="width: 31%"
          label="家长照片"
          placeholder=""
          readonly
          :border="false"
        />
        <div class="uploader">
          <div style="width: 100%; height: 100%">
            <img
              src="@/assets/upload.png"
              class="up-img"
              v-if="headImg2 == ''"
            />
            <img :src="headImg2" class="up-img" v-else />
            <input
              class="uploader-div"
              ref="uploadVideo"
              type="file"
              id="logimg"
              accept="image/*"
              @change="onRead2($event)"
            />
          </div>
          <div class="up2">
            <img
              src="@/assets/upload.png"
              class="up-img"
              v-if="headImg3 == ''"
            />
            <img :src="headImg3" class="up-img" v-else />
            <input
              class="uploader-div"
              ref="uploadVideo"
              type="file"
              id="logimg"
              accept="image/*"
              @change="onRead3($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="submit">提交</div>
    <!-- 选择 -->
    <!-- 底部弹出 -->
    <van-popup v-model="show1" position="bottom">
      <van-picker
        title="选择性别"
        show-toolbar
        value-key="label"
        :columns="sexList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="show2" position="bottom">
      <van-picker
        title="残疾类型"
        show-toolbar
        value-key="label"
        :columns="disability_typeList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="show3" position="bottom">
      <van-picker
        title="残疾等级"
        show-toolbar
        value-key="label"
        :columns="disability_levelList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="show4" position="bottom">
      <van-picker
        title="政治面貌"
        show-toolbar
        value-key="label"
        :columns="political_statusList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showDate = false"
        @confirm="confirmDate"
      />
    </van-popup>
    <van-overlay :show="showOver">
      <div class="wrapper">
        <div class="block">
          <img src="@/assets/success.png" />
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showLoad">
      <div class="wrapper">
        <van-loading
          type="spinner"
          vertical
          color="#E26A26"
          text-color="#E26A26"
          >上传中...</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>
<script>
import {
  Icon,
  Toast,
  Field,
  CellGroup,
  Popup,
  Picker,
  DatetimePicker,
  Overlay,
  Dialog,
  Loading,
} from "vant";
import {
  dictData,
  upLoad,
  peopleRegister,
  peopleAdd,
  detail,
  bindUser,
  unbindUser,
  config,
  positionDetail,
} from "@/api/apiData";
import getFormat from "@/until/getFormatDates";
import GetQueryString from "@/until/getParams";

export default {
  components: {
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Loading.name]: Loading,
    [DatetimePicker.name]: DatetimePicker,
    [Overlay.name]: Overlay,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      has_children: 0,
      showLoad: false,
      showOver: false,
      headImg: "",
      headImg2: "",
      headImg3: "",
      text: "",
      sexList: [],
      disability_typeList: [], //残疾类型
      disability_levelList: [], //残疾等级
      political_statusList: [], //政治面貌
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      name: "",
      sex_t: "",
      disability_type_t: "",
      disability_level_t: "",
      political_status_t: "",
      info: {
        id_photo: "",
        disability_cert_no: "",
        disability_type: "",
        disability_level: "",
        name: "",
        id_number: "",
        sex: "",
        birthday: "",
        household_address: "",
        family_address: "",
        telephone: "",
        political_status: "",
        parents_photo: "", //家长照片
        parents_photo2: "", //家长照片
      },
      minDate: new Date(1600, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(2000, 5, 1),
      showDate: false,
      site: "",
      chartText: "绑定",
      is_bind_wechat: 0,
      appid: "wx0d3c07901732c258",
      titImg:''
    };
  },
  mounted() {
    this.getData();

    let token = localStorage.getItem("token");
    window.document.title = "添加残疾人信息";
    if (!token) {
      Toast.fail("请登录");
      this.$router.push("/login");
    }
    this.getInfo();
    this.getConfig();
    this.getPath();

    // let id = this.$route.query.id
    // localStorage.setItem("site_id",id);
  },
  methods: {
    // 获取首页图片
    getPath() {
      positionDetail({ position_id: 4 }).then((res) => {
        if (res.code == 1) {
          this.titImg = res.data.path;
        } else {
          Toast.fail(res.msg);
        }
      });
    },
    // 退出登陆
    out() {
      let that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定要退出登陆吗？",
      })
        .then(() => {
          // on confirm
          localStorage.clear();
          var url = window.location.href; //获取当前页面的url
          if (url.indexOf("code") != -1) {
            //判断是否存在参数
            url = url.replace(/(\?|#)[^'"]*/, "");
            console.log(url);
            window.location.href = url + "#/login";
          } else {
            that.$router.push("/login");
          }
        })
        .catch(() => {
          // on cancel
        });
    },

    // 获取配置
    getConfig() {
      config().then((res) => {
        console.log(res.data);
        this.appid = res.data.appId;
      });
    },
    // 微信绑定
    bind() {
      let that = this;
      let href = window.location.href;
      let redirect_url = encodeURIComponent(href);
      let appid = this.appid;
      Dialog.confirm({
        title: "提示",
        message: "确定要" + that.chartText + "微信吗",
        beforeClose(action, done) {
          if (action === "confirm") {
            if (that.is_bind_wechat == 0) {
              window.location.href = `https://ncymkj.doudou00.com/get-weixin-code.html
?appid=${appid}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_re`;
            } else {
              unbindUser().then((res) => {
                if (res.code == 1) {
                  Toast.success(res.msg);
                  that.chartText = "绑定";
                  that.is_bind_wechat = 0;
                } else {
                  Toast.fail(res.msg);
                }
              });
            }
            return done();
            // peopleAdd(that.info).then((res) => {
            //   if (res.code == 1) {
            //     that.showOver = true;
            //   } else {
            //     Toast.fail(res.msg);
            //   }
            //   return done();
            // });
          } else {
            // 拦截取消操作
            return done();
          }
        },
      });
    },
    // 获取用户详情
    getInfo() {
      detail().then((res) => {
        if (res.code == 1) {
          this.site = res.data.site.name;
          this.has_children = res.data.site.has_children;
          this.is_bind_wechat = res.data.is_bind_wechat;
          if (res.data.is_bind_wechat == 1) {
            this.chartText = "解绑";
          } else {
            this.chartText = "绑定";
          }
          let codeArr = GetQueryString("code");
          if (codeArr.length != 0 && res.data.is_bind_wechat == 0) {
            let code = codeArr[codeArr.length - 1];
            bindUser({ code: code }).then((res) => {
              this.$router.push("/form");
              if (res.code == 1) {
                Toast.success(res.msg);
                this.chartText = "解绑";
                this.is_bind_wechat = 1;
              } else {
                Toast.fail(res.msg);
              }
            });
          }
        } else {
          Toast.fail(res.msg);
          this.$router.push("/login");
        }
      });
    },
    // 获取下拉数据
    getData(k) {
      dictData({ key: k }).then((res) => {
        this.sexList = res.data.sex;
        this.disability_typeList = res.data.disability_type;
        this.disability_levelList = res.data.disability_level;
        this.political_statusList = res.data.political_status;
      });
    },
    // 上传
    onRead(event) {
      let that = this;
      let file = event.target.files[0];
      // file.name = file.name.replace("img-","")
      let binaryData = [];
      binaryData.push(file);
      let url = window.URL.createObjectURL(new Blob(binaryData));
      // Toast(url)
      if (file) {
        this.showLoad = true;
        let formData = new FormData();
        formData.append("file", file);
        upLoad(formData).then((res) => {
          if (res.code == 1) {
            that.headImg = url;
            that.info.id_photo = res.data.path;
            this.showLoad = false;
          } else {
            Toast.fail(res.msg);
            this.showLoad = false;
          }
        });
      }
    },
    onRead2(event) {
      let that = this;
      let file = event.target.files[0];
      // file.name = file.name.replace("img-","")
      let binaryData = [];
      binaryData.push(file);
      let url = window.URL.createObjectURL(new Blob(binaryData));
      // Toast(url)
      if (file) {
        this.showLoad = true;
        let formData = new FormData();
        formData.append("file", file);
        upLoad(formData).then((res) => {
          if (res.code == 1) {
            that.headImg2 = url;
            that.info.parents_photo = res.data.path;
            this.showLoad = false;
          } else {
            Toast.fail(res.msg);
            this.showLoad = false;
          }
        });
      }
    },
    onRead3(event) {
      let that = this;
      let file = event.target.files[0];
      // file.name = file.name.replace("img-","")
      let binaryData = [];
      binaryData.push(file);
      let url = window.URL.createObjectURL(new Blob(binaryData));
      // Toast(url)
      if (file) {
        this.showLoad = true;
        let formData = new FormData();
        formData.append("file", file);
        upLoad(formData).then((res) => {
          if (res.code == 1) {
            that.headImg3 = url;
            that.info.parents_photo2 = res.data.path;
            this.showLoad = false;
          } else {
            Toast.fail(res.msg);
            this.showLoad = false;
          }
        });
      }
    },
    toShow(k) {
      this.name = k;
      let n = this.name;
      if (n == "show1") {
        this.show1 = true;
      }
      if (n == "show2") {
        this.show2 = true;
      }
      if (n == "show3") {
        this.show3 = true;
      }
      if (n == "show4") {
        this.show4 = true;
      }
    },
    onConfirm(e) {
      this.onCancel();
      let n = this.name;
      if (n == "show1") {
        this.info.sex = e.value;
        this.sex_t = e.label;
      }
      if (n == "show2") {
        this.info.disability_type = e.value;
        this.disability_type_t = e.label;
      }
      if (n == "show3") {
        this.info.disability_level = e.value;
        this.disability_level_t = e.label;
      }
      if (n == "show4") {
        this.info.political_status = e.value;
        this.political_status_t = e.label;
      }
    },
    onCancel() {
      let n = this.name;
      if (n == "show1") {
        this.show1 = false;
      }
      if (n == "show2") {
        this.show2 = false;
      }
      if (n == "show3") {
        this.show3 = false;
      }
      if (n == "show4") {
        this.show4 = false;
      }
    },
    // 日期
    confirmDate(e) {
      this.showDate = false;
      this.info.birthday = getFormat(e);
    },
    // 提交
    submit() {
      console.log(this.info);
      let that = this;
      if (this.info.name == "") {
        Toast.fail("请输入您的名字");
        return false;
      }
      if (this.info.id_number == "") {
        Toast.fail("请输入您的身份号码");
        return false;
      }
      // if (this.info.disability_cert_no == "") {
      //   Toast.fail("请输入您的残疾证号");
      //   return false;
      // }
      if (this.info.id_photo == "") {
        Toast.fail("请上传您的照片");
        return false;
      }
      Dialog.confirm({
        title: "提示",
        message: "确定要提交吗",
        beforeClose(action, done) {
          if (action === "confirm") {
            peopleAdd(that.info).then((res) => {
              if (res.code == 1) {
                that.showOver = true;
              } else {
                Toast.fail(res.msg);
              }
              return done();
            });
          } else {
            // 拦截取消操作
            return done();
          }
        },
      });
      // .then(() => {
      //   // on confirm
      // })
      // .catch(() => {
      //   // on cancel
      // });
    },
    toRecord(){
      this.$router.push('/record')
    }
  },
};
</script>
<style lang="less" scoped>
.form {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .block {
    width: 64vw;
    height: 48vw;
    img {
      width: 100%;
    }
  }
  .top-img {
    width: 100%;
  }
  .main {
    margin: 4.267vw;
    .tit {
      color: #fc6407;
      font-size: 4.267vw;
      border-left: solid 1.067vw;
      line-height: 1;
      padding-left: 1.333vw;
      margin-bottom: 1.333vw;
    }
    .form-div {
      margin-bottom: 5.333vw;
    }
    .up {
      align-items: baseline;
    }
    .uploader {
      width: 24.533vw;
      height: 28.533vw;
      position: relative;
      .up-img {
        width: 100%;
        max-height: 100%;
        // height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .uploader-div {
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      .up2 {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 28.533vw;
        top: 0;
      }
    }
  }
  .btn {
    width: 91.467vw;
    height: 12.8vw;
    margin: 20vw auto;
    background: linear-gradient(to left, #fc6207, #f69e00);
    color: white;
    font-size: 5.227vw;
    line-height: 12.8vw;
    text-align: center;
    border-radius: 13.333vw;
  }
  .site {
    position: relative;
    width: 93%;
    color: #fc6407;
    font-size: 3.733vw;
    height: 10.667vw;
    // line-height: 10.667vw;
    justify-content: center;
    padding: 1vw 0;
    margin: 4.267vw auto;
    .icon {
      width: 2.8vw;
      margin-right: 2.133vw;
    }
    .bg5 {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .bind {
    position: absolute;
    top: 37vw;
    right: 0;
    background: #fc6407;
    color: white;
    border-radius: 2.667vw 0 0 2.667vw;
    padding: 0.533vw 1.333vw 0.533vw 2.133vw;
    font-size: 4vw;
    span {
      margin-left: 0.533vw;
    }
  }
  .out {
    position: absolute;
    top: 47.133vw;
    right: 0;
    background: #681102;
    color: white;
    border-radius: 2.667vw 0 0 2.667vw;
    padding: 0.533vw 1.333vw 0.533vw 2.133vw;
    font-size: 4vw;
  }
  .record{
      position: absolute;
    top: 5vw;
    right: 0;
    background: #0e7849;
    color: #fbed02;
    border-radius: 2.667vw 0 0 2.667vw;
    padding: 0.533vw 1.333vw 0.533vw 2.133vw;
    font-size: 4vw;
  }
}
</style>